import { getByName } from 'o365.vue.composables.target.ts';

const vTarget = {
    mounted(el, binding, vnode, prevVnode) {
        const target = getByName(binding.arg);
        if (target) {
            el.__o_target = {};
            el.__o_target.handler = binding.value?.handler
                ? (...args) => binding.value.handler(target.handler, el, ...args)
                : (...args) => target.handler(el, ...args);
            el.__o_target.event = binding.value?.event ?? target.event;
            el.addEventListener(target.event, el.__o_target.handler);
        } else {
            console.warn(`No target found with name ${binding.arg}`);
        }
    },

    unmounted(el, binding, vnode, prevVnode) {
        if (el.__o_target) {
            el.removeEventListener(el.__o_target.event, el.__o_target.handler);
        }
    }
}

export default vTarget;